import React, {useRef, useEffect, createRef} from "react";
import { Seo } from "../../components/Seo";
import {graphql} from 'gatsby';
import Carousel from "../../components/Projects/Carousel";
import { StaticImage } from "gatsby-plugin-image";
import ContactForm from "../../components/ContactForm";

const EasySleepProject = ({data}) => {
  const textRef = useRef([]);
  const imageRef = useRef([]);
  for(let i = 0; i < 3; i++) {
    textRef.current[i] = createRef();
    imageRef.current[i] = createRef();
  }

  const resizeSection = () => {
    for(const[k,i] of Object.entries(textRef.current)) {
      if(i.current || false) {
        let mheight = i.current.offsetHeight + 40;
        
        imageRef.current[k].current.style.maxHeight = `${mheight}px`;
      }
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeSection);
    resizeSection();
  }, []);

  const projects = data.allPrismicMixedContentProject.edges.map((i,k) => ({
    to: i.node.uid,
    name: i.node.data.title,
    description: i.node.data.description,
    src: i.node.data.cover_image.url
  }))
  return (
    <main>
      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[0]}
            className="py-24 px-16 xl:px-32">
            <h1 className="font-black text-4xl lg:text-5xl mb-12">
              Easy Sleep.<br />
              Inspired locally.<br />
              Manufactured in China.
            </h1>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci is proud to support Easy Sleep Australia by helping them launch their very first private branded range of products. This undertaking involved manufacturing an extremely high quality range of electronically adjustable bed bases – massages, remotes, German motors, warranties and all.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              When Easy Sleep approached Sourci in 2019 they were cemented as an already well established name in the Australian bedding market, whilst also growing at an extremely healthy pace - supplying beds, mattresses, and bedding accessories to Australians young & old.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Their management team were very clever in identifying a huge opportunity. They were bold and acted upon it quickly with Sourci’s help, and have benefited tremendously – with game-changing impacts to both their brand and their overall business.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[0]}>
              <StaticImage src="../../images/easy-sleep-1.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container grid-cols-1 lg:grid-cols-5 mx-auto px-6 grid text-center gap-x-12 gap-y-12">
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>3x</span>
            </p>
            <p className="font-black text-xl">SPEED TO MARKET</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>100%</span>
            </p>
            <p className="font-black text-xl">EXECUTION SUCCESS</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>40%+</span>
            </p>
            <p className="font-black text-xl">IMPROVEMENT IN MARGIN</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>10</span>
            </p>
            <p className="font-black text-xl">YR MANUFACTURER WARRANTY</p>
          </div>
          <div>
            <p className="font-black text-5xl lg:text-6xl mb-3">
              <span>ZZz</span>
            </p>
            <p className="font-black text-xl">A TREMENDOUS SLEEP</p>
          </div>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div className="flex h-full"
            ref={imageRef.current[1]}>
              <StaticImage src="../../images/easy-sleep-2-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
          <div ref={textRef.current[1]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              Here’s how we made amazing happen for Easy Sleep
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              The team at Easy Sleep found that the market for electric adjustable beds was growing fast, the beds they stocked were selling extremely well. The issue was that they were purchasing them wholesale, and the maker of these beds wouldn’t give them additional volume discounts for the hundreds that they were selling, and doing such a good job of promoting.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              The solution was plain and simple, manufacture their own range and supercharge their margins, after all they already had the volume. The requirement was also very clear: Quality & Workmanship must only go up, this cannot be sacrificed at any cost.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci hit a home run for Easy Sleep.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Our global teams spent weeks scouring the manufacturing landscape across the Asian continent, assessing suitable manufacturers, qualifying and background checking, auditing and inspecting.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Careful attention had to be paid to their high specification requirements. We set out to find the best manufacturers out there.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Easy Sleep's requirements were extremely discerning, their beds needed to incorporate:
              <ul className="list-disc list-inside ml-4 mt-4">
                <li>Multiple German-made motors within each bed</li>
                <li>Massage settings</li>
                <li>Programmable remote controls</li>
                <li>300+ KG weight load ratings</li>
                <li>Reliable manufacturer warranties</li>
                <li>Customisable branding & packaging</li>
                <li>USB Charging ports</li>
                <li>LED lighting options</li>
                <li>6 size combinations from Single right through to King +</li>
              </ul>
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              We found the most professional and experienced factories in this space, who might be manufacturing for the biggest names in the bedding industry globally. We presented our top 2 recommendations within 2 months, meeting and of course exceeding all of the set requirements, with warranties, spare parts, after-care, and industry leading technological advancements. Needless to say the sampling process was flawless. Easy Sleep received their trial models and the beds were exceptional.
            </p>
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-24">
        <div className="xl:container mx-auto px-6 text-center">
          <h3 className="font-black text-5xl lg:text-6xl">
            Sourci puts product sourcing wizardry at the heart of your team. Push the button.
            <span className="block text-sourci-pink">Make amazing happen.</span>
          </h3>
        </div>
      </section>

      <section>
        <div className="grid lg:grid-cols-2 items-start">
          <div ref={textRef.current[2]}
            className="py-24 px-16 xl:px-32">
            <h3 className="font-black text-2xl lg:text-3xl mb-12">
              So were the margins
            </h3>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci managed their production planning, executed confidentiality agreements with the factory, drafted legal sales contracts outlining quality control, timings, and warranties, worked between parties to customise branding on the beds, remotes, and packaging, and managed manufacturing, quality control, shipping clearance and delivery perfectly.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Sourci takes care of their re-ordering and supply chain full time, so they can focus on serving their customers.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              Easy Sleep’s own “Millennium Series” adjustable beds are now their best sellers, provide them with the best margins, they are even registered as a class 1a Medical Device with the Therapeutic Goods Administration (TGA), and just like their beds, their procurement & supply chain is well… fully automated. So their team and customers can all sleep soundly at night.
            </p>
            <p className="text-xl lg:text-2xl mb-8">
              EasySleep & Sourci are now working on various other private label ranges together. They clearly did their research and picked a winner before they hopped into bed with a sourcing partner.
            </p>
          </div>
          <div className="flex h-full"
            ref={imageRef.current[2]}>
              <StaticImage src="../../images/easy-sleep-3-1875w.jpg"
                alt=""
                className="object-cover min-w-[100%]" />
          </div>
        </div>
      </section>

      <section className="bg-[#f8f8f8] py-32">
        <div className="xl:container lg:grid lg:grid-cols-2 mx-auto px-6">
          <div className="text-black">
            <p className="text-4xl md:text-6xl font-black mb-8">The time<br /> is now.</p>
            <p className="text-black text-4xl mb-8 font-black">Let's make amazing<br /> happen today.</p>
            <p className="text-3xl mb-2">
              <a href="tel:+611300768724"
                className="hover:underline">1300 SOURCI</a>
            </p>
            <p className="text-3xl mb-2">
              <a href="mailto:hello@sourci.com.au"
                className="hover:underline">hello@sourci.com.au</a>
            </p>
          </div>
          <div className="mt-12 lg:mt-0">
            <ContactForm bg="light"
              location="Case Study - Easy Sleep" />
          </div>
        </div>
      </section>
      <Carousel projects={projects}
        project="easy-sleep"
        title="Check out our other Projects" />
    </main>
  )
}

export const query = graphql`
  query {
    allPrismicMixedContentProject {
      edges {
        node {
          data {
            title
            description
            cover_image {
              url
            }
          }
          uid
        }
      }
    }
  }
` 

export default EasySleepProject;

export function Head() {
  return (
    <Seo title="Easy Sleep | Our Projects | Sourci"
      description="Our global teams spent weeks scouring the manufacturing landscape across the Asian continent, assessing suitable manufacturers, qualifying and background checking, auditing and inspecting."
      canonical="/projects/easy-sleep" />
  )
}